@import '../../../assets/style/_var.scss';

body.Loading{
    overflow: hidden;
}
.container_loader{
    position: fixed;
    left:0px;
    height: 0px;
    background:$bgblack;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 100000;
    img{
        width: 260px;
    }
    @media(max-width:1200px){
        img{
            width: 180px;
        }
    }
}
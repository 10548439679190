@import '../../../assets/style/_var.scss';

.header_style{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    transition: transform 0.4s;
    .navBar{
        padding-top: 20px;
        padding-bottom: 20px;
        .col_brand{
            .brand{
                background: url(../../../assets/header/logo_new.jpeg) no-repeat; 
                width: 63px;
                height: 75px;
                display: block; 
                font-size: 0px;   
                background-size: 100%;

                @media(max-width:768px){
                    width: 45px;
                    height: 53px;
                }
                   
            }
        }
        .col_actions{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            ul{
                display: flex;
                margin-right: 16px;
                @media(max-width:650px){
                    width: 210px;
                }
                @media(max-width:400px){
                    width: 184px;
                }
                li{
                    margin-right: 15px;
                    @media(max-width:650px){
                        margin-right: 8px;
                    }
                    @media(max-width:400px){
                        margin-right: 2px;
                    }
                    a{
                        display: block;
                        width: 37px;
                        height: 37px;
                        transition: all .25s;
                        &.icon_openSea{
                            background: #fff;
                            mask: url(../../../assets/header/icon_opensea.svg) no-repeat;
                            -webkit-mask: url(../../../assets/header/icon_opensea.svg) no-repeat;
                        }
                        &.icon_twitter{
                            background: #fff;
                            mask: url(../../../assets/header/icon_twitter.svg) no-repeat;
                            -webkit-mask: url(../../../assets/header/icon_twitter.svg) no-repeat;
                        }
                        &.icon_instagram{
                            background: #fff;
                            mask: url(../../../assets/header/icon_instagram.svg) no-repeat;
                            -webkit-mask: url(../../../assets/header/icon_instagram.svg) no-repeat;
                        }
                        &.icon_discord{
                            background: #fff;
                            mask: url(../../../assets/header/icon_discord.svg) no-repeat;
                            -webkit-mask: url(../../../assets/header/icon_discord.svg) no-repeat;
                        }
                        &:hover{
                            &.icon_openSea{
                                background: #1868B7;
                            }
                            &.icon_twitter{
                                background: #1DA1F2;
                            }
                            &.icon_instagram{
                                background: #4267B2;
                            }
                            &.icon_discord{
                                background: #7289DA;
                            }
                        }
                    }
                }
            }
            .btn_menu{
                position: relative;
                top:4px;
                display: inline-block;
                width: 40px;
                height: 36px;            
                background: #fff;
                mask: url(../../../assets/header/Icon-menu.svg) no-repeat;
                -webkit-mask: url(../../../assets/header/Icon-menu.svg) no-repeat;
                border:0px;
                cursor: pointer;
                transition: all .25s;
                

                &.btn_closeMenu{
                    top:-6px;
                    transform: rotate(180deg);
                    
                }
            }
           
        }
        @media(max-width:1390px){
            .row-inner{
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }

}

.scroll-down .header_style {
    transform: translate3d(0, -100%, 0);
}
.scroll-up .header_style {
    transform: none;
    background: rgba(0,0,0,0.5);
}

.sidebar{
    position: fixed;
    background: $sidebarBg;
    right: 0px;
    height: 100%;
    width: 300px;
    z-index: 1;
    width: 550px;
    text-align: right;
    padding: 160px 100px 0  100px;
    transition: transform 0.4s;
    overflow-y: auto;
    z-index: 100;

    @media(max-width:768px){
        width: 300px;
        padding: 70px 20px 0  20px;
    }
    
    &.hideSidebar{
        transform:translate3d(100%, 0, 0) ;
    }

    ul{
        li{
            
            a{
                font-family: $basefont;
                font-size: 18px;
                font-weight: 300;
                color: $textwhite;
                text-decoration: none;
                padding-top: 15px;
                padding-bottom: 15px;
                display: block;
                border-bottom: 1px solid $borderwhite-20;
                transition: all .25s;
                @media(max-width:768px){
                    font-size: 14px;
                }
                &:hover{
                    color:$textwhite5;
                    border-bottom-color: $textwhite5;
                }
            }
        }
    }
}
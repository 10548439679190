@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

$basefont: 'Poppins', sans-serif;

$sidebarBg: rgba(0, 0, 0, 0.7);
$bgwhite:#fff;
$bgblack:#000;
$bgYellow:rgb(231, 201, 110);
$bgYellowLight:rgb(130, 101, 15);

$textwhite:#fff;
$textwhite5:rgba(255,255,255,0.5);
$textblack:#000;
$textYellow:#E8C96F;

$borderwhite-20:rgba(255,255,255,0.2);
$borderblack:rgba(0,0,0,1);
@import '../../../assets/style/_var.scss';

.homeSection{
  height: 1100px;
  @media(max-width:1366px){
    height: 700px;
  }
}
h2.title-text{
  text-align: center;
  margin-bottom: 32px;
  strong{
    font-size: 48px;
    font-weight: 600;
    text-transform: uppercase;
    display: block;
    color: $textYellow;
    line-height: 45px;
  } 
  span.subTitle{
    font-size: 26px;
    font-weight: 300; 
    display: block;  
    font-style: italic;
  }
  @media(max-width:768px){
    strong{
      font-size: 28px;
      line-height: 35px;
    }
    span.subTitle{
      font-size: 20px;  
    }
  }
}


.parrot_canvas{
    height: 500px;
    
}
.parrot_canvas2{
    height: 1000px; 
    position: relative;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    // background: url(../../../assets/crypto-bg.jpg) center center  no-repeat;
    background-size: cover;
   
  }
  .wrapper_style{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    width: 100%;
    overflow: hidden;  
    background-size: cover;
    color: #fff;
  }
  .wrapper_style a{
    color: #fff;
    font-size: 24px;
  }
  .wrapper_style2{
    float: left;
    width: 100%; 
    background-size: cover;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    
    .videoBg{
      position: absolute;
      width: 100%;
      height: 100vh;
      
      
      &::before{
        position: absolute;
        content: '';
        display: block;
        left:0px;
        bottom:0px;
        height: 250px;
        z-index: 10;
        width: 100%;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.16+0,1+100 */
      background: -moz-linear-gradient(top,  rgba(0,0,0,0.16) 0%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  rgba(0,0,0,0.16) 0%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  rgba(0,0,0,0.16) 0%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#29000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */

      }
      
    }
    video{
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      object-fit: cover;
      z-index: 0;
        
    }

    .welcome{
      color: #fff;
      text-align: center;
      position: absolute;
      max-width: 800px;
      width: 100%;
      margin: 0 auto;
      left:0px;
      right:0px;
      z-index: 10;
      bottom: 28%;      

      @media(max-width:1700px){
        bottom: 28%;
      }
      @media(max-width:1366px){
        bottom: 24%;
      }
      
      @media(max-width:750px){
        padding-top: 10%;
      }
      img.parr-img{
        max-width: 100%;
      }

      p.wText{
        font-size: 42px;
        font-weight: 600;
        color: #fff;    
        margin-bottom: 25px;           
      }
      p.wTextSocity{
       
        margin: 0;
        font-size: 70px;
        line-height: 70px;
        padding: 0;
        margin: 0;
        font-weight: 700;
        color: #fff;  
        text-transform: uppercase;      
        @media(max-width:750px){
          font-size: 50px;
          line-height: 50px;
          line-height: auto;
          padding-left: 15px;
          padding-right: 15px;
          margin-bottom: 20px;
        }
        @media(max-width:450px){
          font-size: 30px;
          line-height: 50px;
          line-height: auto;
          padding-left: 15px;
          padding-right: 15px;
          margin-bottom: 20px;
        }
      }
     
    }
    
  }
  
  p.wTextLast{
    margin: 0;
    font-size: 46px;
    font-weight: 700;
    color: #fff;
    position: absolute;
    bottom:20%;
    left:0;
    right: 0;
    margin:0 auto;
    text-align: center;

    @media(max-width:1366px){
      bottom:15%;
    }
  
    @media(max-width:750px){
      font-size: 16px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .container-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1364px;
    text-align: center;
    &.v-container{
      max-width: 95%;
    }
    &.cfull{
      max-width: 100%;
    }

    @media(max-width:1370px){
      width: 95%;
    }

    h2.video-title{
      margin: 0;
      font-size: 40px;
      font-weight: 400;
      color: #fff;
      margin-top: 110px;
    }
    p{
      margin: 0;
      font-size: 16px;
      line-height: 34px;
      font-weight: 300;
      color: rgba(255,255,255,0.8);
      margin: 0 auto;
      width: 100%;
      margin-bottom: 46px;
    }
}

@keyframes infinite-spinning {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


.row-inner{
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  .col-full{
    width: 100%;
  }
  .col-left{
    width: 50%;

    @media(max-width:1108px){
      width: 100%;
      padding-bottom: 20px;
    }
    h6{
      font-size: 20px;
      color: #E8C96F;
      font-weight: 400;
      margin: 0;
    }
    h2{
      font-size: 46px;
      line-height: 58px;
      color: #fff;
      font-weight: 400;
      margin: 0;

      @media(max-width:768px){
        font-size: 31px;
        line-height: 37px;
      }
    }
  }
  .col-right{
    width: 50%;
    padding-right: 60px;
    padding-left: 60px;
    @media(max-width:1108px){
      width: 100%;
      padding-right: 0px;
      padding-left: 0px;
    }
    h3{
      font-size: 20px;
      line-height: 40px;
      color: #fff;
      font-weight: 400;
      margin: 0 0 0 0;
      margin-bottom: 14px;
    }
    ul{
      margin: 0;
      padding: 0;

      li{
        position: relative;
        padding-left: 30px;
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: 300;
        color: #fff;
        &:before{
          left: 0;
          top: 50%;
          position: absolute;
          content: '';
          width: 10px;
          height: 10px;
          border-top: 1px solid #E8C96F;
          border-right: 1px solid #E8C96F;
          transform: rotate(45deg) translateY(-50%);      
        }
      }
    }
  }
  p{
      font-size: 16px;
      line-height: 26px;
      color: rgba(255,255,255,1);
      font-weight: 300;
      margin: 0 0 0 0;
      margin-bottom: 32px;

      @media(max-width:768px){
        font-size: 14px;
        line-height: 22px;
      }

      &.text-heilighted{
        padding-left: 25px;
        color: #E8C96F;
        font-size: 20px;
        line-height: 30px;        
      }
  }
}

#secOurTeam{
  position: relative;
  padding-top:280px;
  padding-bottom: 20px;
  @media(max-width:1366px){
    padding-top:80px;
  }
  @media(max-width:768px){
    padding-top:60px;
  }
  h2.team-title{
    font-size: 48px;
    color: #fff;
    font-weight:600 ;
  }
}

#secFAQ{
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  h2.team-title{
    font-size: 48px;
    color: #fff;
    font-weight:600 ;
  }
}

#secFAQ{
  padding-top: 10%;
  padding-bottom: 10%;
  .col-left{
    padding-right: 16%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media(max-width:1108px){
      padding-right: 20px;
      padding-left: 20px;
    }
    p{
      font-size: 16px;
      color: #fff;
      line-height: 30px;
      @media(max-width:1108px){
        text-align: center;
      }
    }
  }
  .col-full{
    padding-left: 25px;
    .accordion{
      border:0px;
    }
    .accordion__button{
      background: transparent;
      position: relative;
      color: rgb(249, 231, 175);
      border-bottom: 1px solid rgba(255,255,255,0.1);
      padding-left: 0;
      
     
      &::before{
        position: absolute;
        right: 0px;
      }
    }
    .accordion__button[aria-expanded="true"]{
      color: rgba(232, 201, 111, 1);
      border-bottom:1px solid rgba(255, 86, 86, 1);
    }
    .accordion__panel{
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
  }
  .col-full{
    width: 100%;
  }
}

.home-video-mask{
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;

  video{
    width: 100%;
    height: 100%;
  }
}

.parrot_sticky{
  position: sticky;
  position: -webkit-sticky;
  top: 0; /* required */
}


// Video Section 
.vWrap{
  padding-top: 108px;
  padding-bottom: 58px;
  position: relative;

  &::before{
    position: absolute;
    content: '';
    display: block;
    left:0px;
    top:0px;
    height: 250px;
    z-index: 10;
    width: 100%;
   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+3,000000+100&1+0,0.16+100 */
background: -moz-linear-gradient(top,  rgba(0,0,0,1) 0%, rgba(0,0,0,0.97) 3%, rgba(0,0,0,0.16) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.97) 3%,rgba(0,0,0,0.16) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.97) 3%,rgba(0,0,0,0.16) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#29000000',GradientType=0 ); /* IE6-9 */


  }

  @media(max-width:768px){
    padding-top: 38px;
    padding-bottom: 38px;
  }
}
.video_wrap{
  position: relative;
  max-width: 1627px;
  margin: 0 auto;
  width: 100%;
  img{
    max-width: 100%;
    width: 100%;
  }

.btn-playVideo{
  position: absolute;
  top: 50%;
  left:50%;
  transform: translate(-50%, -50%);
  width: 137px;
  height: 137px;
  background: url(../../../assets/playIcon.svg) center no-repeat;
  
  z-index: 100;

  &::after{
    content: '';
    position: absolute;
    width: 137px;
    height: 137px;
    background: url(../../../assets/playButton.svg) center no-repeat;
    border-radius: 50%;
    left: 0px;
    top:0px;
    animation: infinite-spinning;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

  }

  @media(max-width:768px){
    width: 100px;
    height: 100px;
    background-size: 75px;
    &::after{
      width: 100px;
      height: 100px;
      background-size: 100%;
    }
  }
}
}

// About content

.aboutSec{
  padding-top: 58px;
  padding-bottom: 58px;
 @media(max-width:758px){
    padding-top: 18px;
    padding-bottom: 28px;
 }
}

#abContent{
  text-align: center;
  padding-bottom: 38px;

  @media(max-width:768px){
    padding-bottom: 0px;
  }
  
  h3{
    text-align: center;
    font-weight: 300;
    font-size: 20px;
    line-height: 37px;
    // text-shadow: 0px -1px 0px rgba(255, 255, 255, 1);
    color: $textYellow;
    margin-top: 0;

    @media(max-width:1368px){
      font-size: 16px;
      line-height: 31px;
    }
  }
  p{
    text-align: center;
  }
  .abtList{
    display: inline-block;
    margin: 0 0 25px 0;
    padding: 0;
    li{
      display: inline-block;
      position: relative;      
      margin: 0 29px;
      
      &::before{
        position: absolute;
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 16px;
        background: $bgYellow;
        left:-30px;
        top:52%;
        transform: translateY(-50%);

      }
      
      @media(max-width:768px){
        font-size: 13px;
        &::before{
          width: 10px;
          height: 10px;
        }
      }

     
    }
  }
}

// roadmap section


.roadSectoin{
  
    .col-full{
      position: relative;
      overflow: hidden;
  
    }
}

#secRoadmap{
  position: relative;   
 
  .roadMapVideoSection{
    position: relative;   
    min-height: 800px; 
   
    video{
      width: 100%;
      position: relative;
    }
  }
  // .roadMapStyle{
  //   position: absolute;
  //   z-index: 1000;
  //   right: 33px;
  //   width: 500px;
  //   top: 0;
  //   li{
  //     display: block;
  //     background: rgba(255,255,255,0.08);
  //     padding:25px;
  //     bottom: 0px;
  //     margin-bottom: 16px;
  //     &:before{
  //       display: none;        
  //     }
  //     h2{
  //       font-weight: 400;
  //       font-size: 26px;
  //       margin-bottom: 10px;
  //       margin-top: 0;
  //       color: $textYellow;
  //     }
  //     p{
  //       font-size: 16px;
  //       line-height: 30px;
  //       margin-bottom: 0;
  //       strong{
  //         color: $textYellow;
  //         font-weight: 600;
  //       }
  //     }
  //   }
  // }
  .scroll-container{
    z-index: 1000;
    position: absolute;
    height: 460px;
    bottom: 30px;
    width: 100%;
  }
  .progressBar{
    position: absolute;
    left: 0;
    top: 0;
    border:2px solid #900;
    width: 9470px;
    display: none;

    img{
      width: 100%;
    }
  }
  .roadMapStyle{
    position: relative;
    z-index: 10000;
    left: 0;
    margin-top: 50px;
    padding: 0;
    display: flex;
   
    li{
      display: block;
      // background: rgba(255,255,255,0.08);
      padding:25px;
      bottom: 0px;
      margin-bottom: 16px;
      width: 100%;
      position: relative;
      text-align: center;
      width: 100%;
      min-width: 100%;
      display: flex;
      justify-content: center;
      &:before{
        position: absolute; 
        content:'';
        width: 50%;
        height: 1px;
        background: $bgYellowLight;
        display: block; 
        left:0px;
        top:-4px;
      }
      &:after{
        position: absolute; 
        content:'';
        width: 50%;
        height: 1px;
        background: $bgYellowLight;
        display: block; 
        left:50%  ;
        top:-4px;
      }

      &:nth-child(1){
        &::before{
          display: none;
        }
      }
      &:nth-child(7){
        &::after{
          display: none;
        }
      }
     
      .roadMapItem{
        position: relative;
        max-width: 650px;
        opacity: 0;
        transition: opacity 2000ms;
        @media(max-width:768px){
          max-width: 250px;
          opacity: 1;
        }
        &:after{
          position: absolute;
          content:'';
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background: $bgYellow;
          top:-40px;
          left:0px;
          right:0px;
          margin:0 auto;
        }
        
        h2{
          font-weight: 400;
          font-size: 26px;
          margin-bottom: 10px;
          margin-top: 0;
          color: $textYellow;

          @media(max-width:768px){
            font-size: 16px;
            margin-bottom: 5px;
          }
        }
        p{
          font-size: 18px;
          line-height: 30px;
          margin-bottom: 0;
          @media(max-width:768px){
            font-size: 14px;
          line-height: 22px;
          }
          strong{
            color: $textYellow;
            font-weight: 600;
          }
        }
      }
      &.active{
        .roadMapItem{
          opacity: 1; 
          
          &:before{
            position: absolute;
            content:'';
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: $bgYellow;
            top:-40px;
            left:0px;
            right:0px;
            margin:0 auto;
            animation: ripple 1.5s ease-out infinite;
            animation-delay: 1.55s;
          }
        }
      }
      // &:nth-child(1){
      //   .roadMapItem{
      //     &::after{
      //       top: -36px;
      //       left: -61px;
      //     }
      //     &::before{
      //       top: -36px;
      //       left: -61px;
      //     }
      //   }
      // }
      // &:nth-child(2){
      //   .roadMapItem{
      //     &::after{
      //       top: -61px;
      //       left: -61px;
      //     }
      //     &::before{
      //       top: -61px;
      //       left: -61px;
      //     }
      //   }
      // }
      // &:nth-child(3){
      //   .roadMapItem{
      //     &::after{
      //       top: -45px;
      //       left: -61px;
      //     }
      //     &::before{
      //       top: -45px;
      //       left: -61px;
      //     }
      //   }
      // }
      // &:nth-child(4){
      //   .roadMapItem{
      //     &::after{
      //       top: -44px;
      //       left: -61px;
      //     }
      //     &::before{
      //       top: -44px;
      //       left: -61px;
      //     }
      //   }
      // }
      // &:nth-child(7){
      //   .roadMapItem{
      //     &::after{
      //       top: -58px;
      //       left: -36px;
      //     }
      //     &::before{
      //       top: -58px;
      //       left: -36px;
      //     }
      //   }
      // }
    }
  }
  .arrow-drag{
    position: absolute;
    right: 100px;
    bottom:185px;
    z-index: 1000;
    animation: slide1 2s ease-in-out infinite;

    @keyframes slide1 {
      0%,
      100% {
        transform: translate(0, 0);
      }
      25% {
        transform: translate(0, 0);
      }
      50% {
        transform: translate(80px, 0);
      }
    }
    @media(max-width:768px){
      right: 50px;
      bottom:266px;  
      animation: slide1 2s ease-in-out infinite;
      img{
        width: 50px;
        height: auto;
      }
      @keyframes slide1 {
        0%,
        100% {
          transform: translate(0, 0);
        }
        25% {
          transform: translate(0, 0);
        }
        50% {
          transform: translate(40px, 0);
        }
      }
    }
  }

 
}

#secStory{
  position: relative;
  margin-top: -10px;
&::before{
  position: absolute;
  content: '';
  display: block;
  left:0px;
  top:-20px;
  height: 250px;
  z-index: 0;
  width: 100%;
 /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+3,000000+100&1+0,0.16+100 */
  background: -moz-linear-gradient(top,  rgba(0,0,0,1) 0%, rgba(0,0,0,0.97) 3%, rgba(0,0,0,0.16) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.97) 3%,rgba(0,0,0,0.16) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.97) 3%,rgba(0,0,0,0.16) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#29000000',GradientType=0 ); /* IE6-9 */

}
}


.storySection{
  padding-top: 80px;
  padding-bottom: 58px;
  position: relative;

  

  @media(max-width:768px){
    padding-top: 0px;
  }

  .storyCoinVideStyle{
    width: 100%;    
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;

    .stVideoWrapper{      
      video{
        width: 100%;      
      }
    }
    
  }
  .story_coin{
    text-align: center;
    img{
      @media(max-width:1200px){
        width: 300px;
      }
    }
  }
}

.cards_list_wrap{
  display: flex;
  justify-content: center;
  align-items: center;
}
.cards_list{
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  max-width: 530px;
  width: 100%;
  @media(max-width:768px){
    max-width: 246px;
  }
  li{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border:1px solid $bgYellow;
    margin: 10px;
    background: rgba(231, 201, 110, 0.2);

    @media(max-width:768px){
      width: 100px;
      height: 100px;
    }
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(3);
  }
}

@media(max-width:768px){
  .row-inner .col-right.perk_right{
    h3{
      font-size: 16px;
      line-height: 31px;
      text-align: center;
    }
    ul{
      li{
        font-size: 13px;
      }
    }
    p.text-heilighted{
      padding-left: 25px;
      color: #E8C96F;
      font-size: 15px;
    }
  }
}
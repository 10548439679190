.slider_style{
    color: #fff;
    padding-bottom: 50px;

    .slider_item{
        box-sizing: border-box;
        perspective: 1000px;
        margin-top: 44px;
       >div{
            padding-left: 10%;
            padding-right: 10%;
       }
        .s_img{
            width: 320px;
            height: 400px;
            position: relative;
            overflow: hidden;
            margin: 0 auto;
            background-image: none;
            transform: translate3d(0px, 0px, 0px) scale3d(0.9, 0.9, 1) rotate(15deg) skew(0deg, 0deg); 
            transform-style: preserve-3d;
            transition: all .25s;
            
            &:before{
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.4);
                left: 0;
                top: 0;
                z-index: 1;
                transform: translate3d(0px, 0px, 0px) scale3d(0.5, 0.5, 1)  skew(0deg, 0deg); 
                transform-style: preserve-3d;
            }

            @media(max-width:700px){
                width: 280px;
                height: 350px;
            }

            img{
                position: absolute;
                left: -1%;
                top: -1%;
                right: -1%;
                bottom: -1%;
                width: 102%;
                height: 102%;
                max-width: 102%;
                -o-object-fit: cover;
                object-fit: cover;
                transform: translate3d(0px, 0px, 0px) scale3d(0.5, 0.5, 1);         
                transform-style: preserve-3d;
                transition: all .55s;                
            }
        }
    }
    .slick-arrow{
        width: 60px;
        height: 60px;
        border:1px solid #E8C96F;
        border-radius: 50%;
        background: transparent;
        font-size: 0px;
        bottom:0px;
        position: absolute;
        cursor: pointer;


        &.slick-next{
            left:52%;
            &:before{
                position: absolute;
                content: '';
                background: url(../../../assets/arrow-left.svg) center no-repeat;
                width: 31px;
                height: 11px;
                left:50%;
                top:50%;
                transform: translate(-50%, -50%) rotate(180deg);
                transition: all .25s;

                
            }
            &:hover{
                &:before{
                    left:65%;
                }
            }
            @media(max-width:1004px){
                left:55%;
            }
            
        }
        &.slick-prev{
            left:45%;

            &:before{
                position: absolute;
                content: '';
                background: url(../../../assets/arrow-left.svg) center no-repeat;
                width: 31px;
                height: 11px;
                right:20%;
                top:50%;
                transform: translateY(-50%);
                transition: all .25s;
                
            }
            &:hover{
                &:before{
                    right:35%; 
                }
            }
            @media(max-width:1004px){
                left:40%;
            }
            @media(max-width:476px){
                left:30%;
            }
        }
    }
}

.slick-slide{

    
    .s_desc{
        position: relative;
        top:-112px;

        h3{
            font-size: 30px;
            font-weight: 400;
            color: #fff;
            line-height: 33px;
            margin: 0 0 10px 0;
            transform: translateY(-30px);
            transition: all .55s;
            transition-delay: 550ms;
            
        }
        p{
            font-size: 16px;
            font-weight: 300;
            color: #fff;
            line-height: 30px;
            margin: 0;
            transform: translateY(-30px);
            transition: all .55s;
            transition-delay: 550ms;
        }
        h2{
            font-size: 21px;
            font-weight: 500;
            margin: 8px 0 8px 0;
            white-space: nowrap;
            transform: translateY(-30px);
            transition: all .55s;
            transition-delay: 550ms;
        }
        h6{
            font-size: 17px;
            font-weight: 300;
            color: #fff;
            margin: 0 0 0 0;
            transform: translateY(-30px);
            transition: all .55s;
            transition-delay: 550ms;
            display: inline-block;

            .iconTwitter{
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;
                img{
                    width: 30px;
                    margin-right: 10px;
                }
            }
        }
    }
}
.slick-slide.slick-current{
    
    .s_desc{
        display: block;
        top:-77px;
    }

    .s_img{
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotate(15deg); 
        transform-style: preserve-3d;
        
        &:before{
            transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) ; 
            transform-style: preserve-3d;
            transition: all .55s;
            transition-delay: 550ms;
        }

        img{
            transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) ;         
            transform-style: preserve-3d;
            transition: all .55s;
            transition-delay: 550ms;
        }
    }
    .s_desc h3{
        transform: translateY(0px);
        transform-style: preserve-3d;
    }
    .s_desc p{
        transform: translateY(0px);
        transform-style: preserve-3d;
    }
    .s_desc h2{
        transform: translateY(0px);
        transform-style: preserve-3d;
    }
    .s_desc h6{
        transform: translateY(0px);
        transform-style: preserve-3d;
    }
}



@import '../../../assets/style/_var.scss';

.footer_style{
    padding-top: 10px;
    padding-bottom: 30px;
    .container-inner{
        font-family: $basefont;
        font-size: 22px;
        text-align: center;
        font-weight: 300;
        color: $textwhite;

        @media(max-width:768px){
            font-size: 14px;
        }

    }
}